import React from "react"

// Component imports
import Layout from "../components/Layout"
import Section from "../components/Section"
import { ReactTypeformEmbed } from "react-typeform-embed"
import SEO from "../components/SEO"

const Apply = () => {
  return (
    <Layout>
      <SEO
        title="Apply"
        description="Apply to become a staff member on SpaceDelta! Applications are currently open."
      />
      <Section className="flex flex-col flex-1">
        <div className="h-128 md:min-h-164 relative flex-1">
          <ReactTypeformEmbed
            url="https://form.typeform.com/to/z6O1ecNP"
            hideHeaders
            opacity={0}
          />
        </div>
      </Section>
    </Layout>
  )
}

export default Apply
